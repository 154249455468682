import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import { toast } from 'react-toastify'
import Select from 'react-select'
import querystring from 'query-string'

import './remissions.scss'

import {
  isBrowser,
  hasPermission,
  permissionsHasOneOf,
  rolesHasOneOf,
  hasRole
} from '../components/Auth'

import Layout from '../components/Layout'
import { Paginate } from '../components/Paginate'
import { getRemissions } from '../services/api/remissions'
import { Context } from '../Context'
import { getUsers } from '../services/api/users'
import Checkbox from '../components/Checkbox'

export default function RemissionPage ({ location }) {
  const { isAuth } = useContext(Context)

  // read_all_remission (id=99); read_force_remission (id=100); read_remission_as_receptor (id=109)
  if (!isAuth || !permissionsHasOneOf([99, 100, 109])) {
    if (isBrowser()) {
      window.location.href = '/'
    }

    return (
      <Layout withoutHeader className='page-remissions'>
        Cargando...
      </Layout>
    )
  }

  const statuses = [
    { value: '', label: 'Todos' },
    { value: 1, label: 'Abierto' },
    { value: 0, label: 'Cerrado' }
  ]

  const emptyRemissions = {
    remissions: [],
    sessions: [],
    users: [],
    total: 0
  }

  const [remissions, setRemissions] = useState(emptyRemissions)
  const [page, setPage] = useState(0)
  const perPage = 15

  const [receptors, setReceptors] = useState([])
  const queryParams = querystring.parse(location.search)

  const [filters, setFilters] = useState(() => {
    const today = moment().format('YYYY-MM-DD')

    const defaultValues = {
      creation_date1: queryParams.creation_date1,
      creation_date2: queryParams.creation_date2 || today,
      update_date1: queryParams.update_date1,
      update_date2: queryParams.update_date2,
      receiver: parseInt(queryParams.receiver, 10) || null,
      status_details: queryParams.status_details,
      patient_name: queryParams.patient_name,
      consent: queryParams.consent !== undefined ? parseInt(queryParams.consent, 10) : 1,
      boss_check: queryParams.boss_check !== undefined ? parseInt(queryParams.boss_check, 10) : 1,
      document_number: queryParams.document_number,
      status: queryParams.status !== undefined ? parseInt(queryParams.status, 10) : null
    }

    return defaultValues
  })

  // consulta las remisiones cada vez que page cambia
  useEffect(() => {
    const params = filters

    // update_remit_session (id=110)
    if (!hasPermission(110)) {
      params.boss_check = 1
    }

    params.per_page = perPage
    params.page = page
    params.order_by = 'created_at desc'

    getRemissions(params)
      .then(res => {
        if (res.status === 200 && res.data.remissions.length) {
          setRemissions(res.data)
        }
      })
      .catch(err => {
        console.error(err.response)
        setRemissions(emptyRemissions)
      })
  }, [page])

  // obtiene del backend los receptores para el select de filtros
  useEffect(() => {
    // carga receptores
    getUsers({ role_id: 3 })
      .then(res => {
        const receivers = [
          { label: 'Todos', value: '' },
          ...res.data.map(item => {
            return {
              value: item.id,
              label: `${item.name} ${item.name2} ${item.lastname}`
            }
          })
        ]

        setReceptors(receivers)
      })
      .catch(err => {
        console.error(err.response)
        toast.error(err.response.data.message)
      })
  }, [])

  function handleFiltersSubmit () {
    const params = filters

    Object.keys(params).forEach(i => {
      if (params[i] === '' || params[i] === null) {
        delete params[i]
      }
    })

    window.location.search = querystring.stringify(params)
  }

  function handleChangeFilters (e) {
    e.preventDefault()

    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Layout className='page-remissions'>
      <div className='container-remissions'>
        <h1 className='text-center'>REMISIONES RECIBIDAS</h1>

        <div
          className='remission-filters__container'
          style={{
            marginBottom: '4rem'
          }}
        >
          <div
            className='remission-filters__row'
            style={{
              display: 'flex'
            }}
          >
            <label>
              <div>Fecha de creación inicial</div>
              <input
                name='creation_date1'
                type='date'
                placeholder='YYYY-MM-DD'
                className='textfield'
                value={filters.creation_date1}
                onChange={handleChangeFilters}
              />
            </label>
            <label>
              <div>Fecha de creación final</div>
              <input
                name='creation_date2'
                type='date'
                placeholder='YYYY-MM-DD'
                className='textfield'
                value={filters.creation_date2}
                onChange={handleChangeFilters}
              />
            </label>

            <label>
              <div>Fecha de actualización inicial</div>
              <input
                name='update_date1'
                type='date'
                placeholder='YYYY-MM-DD'
                className='textfield'
                value={filters.update_date1}
                onChange={handleChangeFilters}
              />
            </label>
            <label>
              <div>Fecha de actualización final</div>
              <input
                name='update_date2'
                type='date'
                placeholder='YYYY-MM-DD'
                className='textfield'
                value={filters.update_date2}
                onChange={handleChangeFilters}
              />
            </label>

            <label>
              <div>Estado</div>
              <Select
                name='status'
                options={statuses}
                placeholder='Estado'
                className='select'
                classNamePrefix='select'
                value={statuses.find(item => item.value === filters.status)}
                onChange={option => setFilters({
                  ...filters,
                  status: option.value
                })}
              />
            </label>
          </div>

          <div
            className='remission-filters__row'
            style={{
              display: 'flex'
            }}
          >
            <label>
              <div>Receptor</div>
              <Select
                name='receptor'
                options={receptors}
                placeholder='Receptor'
                className='select'
                classNamePrefix='select'
                value={receptors.find(item => item.value === filters.receiver)}
                onChange={option => setFilters({
                  ...filters,
                  receiver: option.value
                })}
              />
            </label>

            <label>
              <div>Descripción</div>
              <input
                name='status_details'
                type='text'
                placeholder='Fragmento de la descripción'
                className='textfield'
                value={filters.status_details}
                onChange={handleChangeFilters}
                autoComplete='ño-completes'
              />
            </label>

            <label>
              <div>Nombre del paciente</div>
              <input
                name='patient_name'
                type='text'
                placeholder='Nombre del paciente'
                className='textfield'
                value={filters.patient_name}
                onChange={handleChangeFilters}
                autoComplete='ño-completes'
              />
            </label>

            <label>
              <div>Cédula del paciente</div>
              <input
                name='document_number'
                type='text'
                placeholder='Cédula del paciente'
                className='textfield'
                value={filters.document_number}
                onChange={handleChangeFilters}
                autoComplete='ño-completes'
              />
            </label>

            <div
              style={{
                display: 'flex',
                marginLeft: '1rem',
                alignItems: 'flex-end'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Checkbox
                  checked={filters.consent === 1}
                  onChange={e => setFilters({
                    ...filters,
                    consent: parseInt(e.target.checked ? 1 : 0, 10)
                  })}
                  containerclassname='size-18'
                />
                <div style={{ marginLeft: '0.25rem' }}>Consentimiento</div>
              </div>

              {/* read_all_remission (id=99) */}
              {hasPermission(99) && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '1rem'
                  }}
                >
                  <Checkbox
                    checked={filters.boss_check === 1}
                    onChange={e => setFilters({
                      ...filters,
                      boss_check: parseInt(e.target.checked ? 1 : 0, 10)
                    })}
                    containerclassname='size-18'
                  />
                  <div style={{ marginLeft: '0.25rem' }}>Aprobado</div>
                </div>
              )}

              <button
                onClick={handleFiltersSubmit}
                style={{
                  cursor: 'pointer',
                  backgroundColor: 'white',
                  display: 'inline-block',
                  border: '1px solid black',
                  marginLeft: '1rem',
                  color: 'black',
                  padding: '0 1rem'
                }}
              >
                Filtrar
              </button>
            </div>

          </div>
        </div>

        <div
          style={{
            padding: '1rem',
            border: '1px solid white',
            margin: '0 0 2rem 0',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            fontSize: '16px'
          }}
        >
          <Paginate
            total={remissions.total}
            page={page}
            perPage={perPage}
            onPageChange={selected => {
              setPage(selected)
            }}
          />
        </div>

        <table className='table'>
          <thead>
            <tr>
              <th>REMISIÓN</th>
              <th>CREADA</th>
              <th>ACTUALIZADA</th>
              <th>RECIBE</th>
              <th>ESTADO</th>
              <th>PACIENTE</th>
              <th>CÉDULA</th>
              <th>CONSENTIMIENTO</th>
              <th>APROBADO</th>
              <th>DESCRIPCIÓN</th>
              <th>ACCIONES</th>
            </tr>
          </thead>
          <tbody>
            {remissions.remissions.map(remission => {
              const receiver = remissions.users.find(
                u => u.id === remission.receiver
              )
              const session = remissions.sessions.find(
                s => s.id === remission.session_id
              )
              const patient = remissions.patients.find(
                p => p.id === session.patient_id
              )

              return (
                <tr key={remission.id}>
                  <td>#{remission.id}</td>
                  <td>
                    {moment
                      .utc(remission.created_at)
                      .local()
                      .format('YYYY-MM-DD HH:mm:ss')}
                  </td>
                  <td>
                    {moment
                      .utc(remission.updated_at)
                      .local()
                      .format('YYYY-MM-DD HH:mm:ss')}
                  </td>
                  <td>{receiver ? receiver.fullname : null}</td>
                  <td>{remission.status ? 'ABIERTA' : 'CERRADA'}</td>
                  <td>{patient ? patient.fullname : null}</td>
                  <td>{patient ? patient.document_number : null}</td>
                  <td>{remission.consent ? 'Sí' : 'No'}</td>
                  <td>{remission.boss_check ? 'Sí' : 'No'}</td>
                  <td>{remission.status_details}</td>
                  <td>
                    {/* read_all_remission (id=99); read_force_remission (id=100); read_remission_as_receptor (id=109) */}
                    {/* aseguradora (id=6); sanidad militar (id=7) */}
                    {!permissionsHasOneOf(
                      [99, 100, 109] || rolesHasOneOf([6, 7])
                    ) ? null : (
                        <>
                          <Link
                            to={`/remit-session/?session_id=${remission.session_id}`}
                            style={{
                              color: 'white',
                              display: 'inline-block',
                              margin: '8px',
                              border: '1px solid white',
                              padding: '0 4px'
                            }}
                          >
                            Ver remisión
                          </Link>

                          {hasRole(6) ? null : (
                            <Link
                              to={`/receptor-details/?remission_id=${remission.id}`}
                              style={{
                                color: 'white',
                                display: 'inline-block',
                                margin: '8px',
                                border: '1px solid white',
                                padding: '0 4px'
                              }}
                            >
                            Ver recepción
                            </Link>
                          )}
                        </>
                      )}

                    {/* reassign_remission (id=112) */}
                    {!hasPermission(112) ? null : (
                      <Link
                        to={`/reassign-receptor/?remission_id=${remission.id}`}
                        style={{
                          color: 'white',
                          display: 'inline-block',
                          margin: '8px',
                          border: '1px solid white',
                          padding: '0 4px'
                        }}
                      >
                        Reasignar
                      </Link>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}
